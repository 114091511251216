import './src/scss/reset.scss'
import './src/scss/main.scss'
import * as navbarStyle from './src/components/Navbar/navbar.module.scss'
import * as missionStyle from './src/components/Mission/mission.module.scss'

function getLinks() {
  const linkHome = document.getElementById('link-home')
  const linkMission = document.getElementById('link-mission')
  const linkApplication = document.getElementById('link-application')
  const linkPartner = document.getElementById('link-partner')
  const linkContact = document.getElementById('link-contact')
  if (linkHome) linkHome.classList.remove(navbarStyle.active)
  if (linkMission) linkMission.classList.remove(navbarStyle.active)
  if (linkApplication) linkApplication.classList.remove(navbarStyle.active)
  if (linkPartner) linkPartner.classList.remove(navbarStyle.active)
  if (linkContact) linkContact.classList.remove(navbarStyle.active)
  return {
    linkHome,
    linkMission,
    linkApplication,
    linkPartner,
    linkContact,
  }
}

window.addEventListener('scroll', () => {
  const navbar = document.getElementById('navbar')
  const { pageYOffset, innerHeight } = window
  if (navbar) {
    if (pageYOffset < innerHeight / 2) navbar.classList.add(navbarStyle.isTop)
    else navbar.classList.remove(navbarStyle.isTop)

    const { linkHome, linkMission, linkApplication, linkPartner, linkContact } = getLinks()

    const homepageHero = document.getElementById('homepage-Hero')
    const homepageMission = document.getElementById('homepage-Mission')
    const homepageApplication = document.getElementById('homepage-Application')
    const homepagePartner = document.getElementById('homepage-Partner')
    const homepageContact = document.getElementById('homepage-Contact')

    homepageMission?.classList.remove(missionStyle.isShow)

    if (homepageMission && homepageMission.getBoundingClientRect().y <= 60) {
      homepageMission.classList.add(missionStyle.isShow)
    }

    if (homepageContact && homepageContact.getBoundingClientRect().y <= 60) {
      linkContact?.classList.add(navbarStyle.active)
    } else if (homepagePartner && homepagePartner.getBoundingClientRect().y <= 60) {
      linkPartner?.classList.add(navbarStyle.active)
    } else if (homepageApplication && homepageApplication.getBoundingClientRect().y <= 60) {
      linkApplication?.classList.add(navbarStyle.active)
    } else if (homepageMission && homepageMission.getBoundingClientRect().y <= 60) {
      linkMission?.classList.add(navbarStyle.active)
    } else if (homepageHero && homepageHero.getBoundingClientRect().y <= 60) {
      linkHome?.classList.add(navbarStyle.active)
    }
  }
})
